import React from 'react';
import styled from 'styled-components';
import {
  ContainerSmall,
  H1,
  Regular,
} from '@kilornd/modules-wrapper/es/components';

import { PrimaryButton, Svg } from 'components';
import { images } from 'utils/images';
import { useRouter } from 'apis/history';
import { grey, primary } from 'styles/colors';
import { FooterDefault } from 'layouts/footers/FooterDefault';

import { NavigationPlainCenter } from 'layouts/navigations/NavigationPlainCenter';

const Error: React.FC = React.memo(() => {
  const { goToLanding } = useRouter();

  return (
    <>
      <Wrapper>
        <NavigationPlainCenter />
        <ContainerSmall>
          <ContentWrapper>
            <IconSad src="sad" />
            <H1 margin="0 0 1rem">404</H1>
            <Regular margin="0 0 2rem" color={grey}>
              Ooops.. looks like you got lost
            </Regular>
            <PrimaryButton
              onClick={goToLanding}
              maxWidth="22rem"
              margin="0 auto"
            >
              Home
            </PrimaryButton>
          </ContentWrapper>
        </ContainerSmall>
      </Wrapper>
      <FooterDefault />
    </>
  );
});

Error.displayName = 'Error';

export default Error;

const IconSad = styled(Svg)`
  display: block;
  margin: 0 auto 1rem;
  max-width: 6rem;
  width: 100%;
  height: 6rem;

  * {
    fill: transparent;
  }
`;

const Wrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  background-image: url(${images['background']});
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  z-index: -1;
`;

const ContentWrapper = styled.div`
  max-width: 34rem;
  width: 100%;
  margin: 0 auto;
  padding: 15vh 0 3rem;
  text-align: center;

  a {
    color: ${primary};

    &:hover {
      text-decoration: underline;
    }

    &:active {
      color: inherit;
      opacity: 0.8;
    }
  }
`;
